.al-main-card {
  background-color: #F2F2F2;
  padding: 16px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.al-sub-card {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 1px solid #E4E4E4;
  border-radius: 9px;
}

.al-main-img{
  width: 84.93px;
  height: 98px;
}

.al-approval-card{
  height: 135px;
  background-color: #FFFFFF;
  display: flex;
  border: 1px solid #E4E4E4;
  border-radius: 9px;
  padding: 20px 40px 20px 40px;
}

.al-transaction-details{
  width: 32px;
  height: 32px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.al-approval-eye-img{
  color: #1A4FD6;
  width: 14px;
  height: 14px;
}

.approval-stage-card{
  width: 754px;
  height: 210px;
  padding: 20px 40px 20px 40px;
  border-radius: 9px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
}

.approval-summary-card {
  width: 100%;
  flex: 1;
  padding: 20px 40px 20px 40px;
  border-radius: 9px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  flex-direction: column;
}