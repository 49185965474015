.form-actions {
  position: sticky;
  bottom: 0;
  padding: 20px 40px;
  background: white;
  border-top: 1px solid #eee;
}

.ad-line {
  height: 21px;
  width: 0px;
  border: 1px solid #e4e4e4;
}

.wa-information-icon {
  width: 11px;
  height: 16px;
}

.datepicker-wrapper {
  height: 43px;
  width: 100%;
  border: 1px solid #e4e4e4;
  background: #ffffff;
  gap: 10px;
  border-radius: 6px;
  display: flex;
}

.datepicker-wrapper-error {
  height: 43px;
  width: 100%;
  border: 2px solid #e85151;
  background: #ffffff;
  gap: 10px;
  border-radius: 6px;
  display: flex;
}

.datepicker {
  height: 40px;
  width: 100%;
  opacity: 1;
  border: none;
  padding-left: 15px;
}

.datepicker-icon {
  height: 18px;
  width: 18px;
}

.icon-add-account {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.select-menu-panel {
  border: 1px solid #e4e4e4;
}

.select-menu-option-box {
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  border-top: 0.5px solid #e4e4e4;
}

.select-menu-option-box:hover {
  background-color: #e4e4e4;
}

.select-menu-option-box-img {
  height: 30px;
}

.select-menu-sub-panel {
  background-color: #ffffff;
  border: 0.5px solid #e4e4e4;
}

.selectBankDropdown {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 2%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  position: absolute;
  bottom: 2%;
  left: 45%;
  margin: auto;
}
