.select-menu-panel {
  border: 1px solid #E4E4E4;
}

.select-menu-option-box {
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4px;
  gap: 10px;
  border-top: 0.5px solid #E4E4E4;
}

.select-menu-option-box:hover {
  background-color: #F5F5F5;
}

.select-menu-option-box-img {
  height: 30px;
}

.select-menu-sub-panel {
  background-color: #FFFFFF;
  border: 0.5px solid #E4E4E4;
}

.selectBankDropdown {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}