.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.totp-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #E5E5E7;
    text-align: center;
}

.totp-card {
    width: 450px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(113, 113, 113, 0.1) 0px 4px 16px, rgba(113, 113, 113, 0.1) 0px 8px 24px, rgba(113, 113, 113, 0.1) 0px 16px 56px;
}

.totp-profile-username {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 2.5rem;
}

.totp-profile-user-handle {
    color: #7d8396;
    font-weight: 500;
    margin-top: 0.2rem;
    margin-bottom: 1.5rem;
}

.totp-qr-image {
    border: 1px solid;
    border-radius: 16px;
    border-color: #E2E2E2;
    width: 190px;
    height: 190px;
    object-fit: none;
    margin: 0 auto;
}

.totp-manual-code {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #E2E2E2;
    line-height: 0.1em;
    margin: 10px 0 20px;
    font-weight: 500;
    margin-top: 2rem;
}

.totp-manual-code span {
    color: #989898;
    background: #fff;
    padding: 0 10px;
    font-size: 0.8rem;
}

.totp-inputs {
    position: relative;
    border: none;
    color: white;
    font-size: 32px;
    font-weight: 200;
    text-align: center;
    padding: 10px;
    width: 100%;
    max-width: 42px;
    height: 55px;
    border-radius: 4px;
    outline: 2px solid #E1E5EA;
}

.totp-inputs-active {
    position: relative;
    border: none;
    font-size: 32px;
    font-weight: 200;
    text-align: center;
    padding: 10px;
    width: 100%;
    max-width: 42px;
    height: 55px;
    border-radius: 4px;
    outline: 2px solid rgb(49, 130, 206);
    color: rgb(49, 130, 206);
}

.totp-inputs-success {
    position: relative;
    border: none;
    font-size: 32px;
    font-weight: 200;
    text-align: center;
    padding: 10px;
    width: 100%;
    max-width: 42px;
    height: 55px;
    border-radius: 4px;
    outline: 2px solid #4BB543;
    color: #4BB543;
}

.totp-inputs:focus {
    outline: 2px solid rgb(49, 130, 206);
    color: rgb(49, 130, 206);
}

.totp-inputs-error {
    position: relative;
    border: none;
    color: white;
    font-size: 32px;
    font-weight: 200;
    text-align: center;
    padding: 10px;
    width: 100%;
    max-width: 42px;
    height: 55px;
    border-radius: 4px;
    outline: 2px solid #ff0033;
    color: #ff0033;
}

/* CSS */
.totp-button-1:disabled {
    margin-left: 10px;
    margin-right: 10px;
    width: 87%;
    background: rgb(206, 206, 206);
    border-radius: 8px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    height: 50px;
    outline: none;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    cursor: not-allowed;
}

.totp-button-1 {
    margin-left: 10px;
    margin-right: 10px;
    width: 87%;
    background: rgb(49, 130, 206);
    border-radius: 8px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    height: 50px;
    outline: none;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-size: 200% 100%;
    transition: all .5s ease-out;
    cursor: not-allowed;
}

.totp-button-1-error {
    margin-left: 10px;
    margin-right: 10px;
    width: 87%;
    background: #ff0033;
    border-radius: 8px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    height: 50px;
    outline: none;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-size: 200% 100%;
    transition: all .5s ease-out;
    cursor: not-allowed;
}

.totp-button-1-success {
    background: #4BB543;
    cursor: pointer;
}

.balance-card-zoom:hover {
    transition: all .4s ease-in-out;
}

.balance-card-zoom:hover {
    transform: scale(1.1);
}

.gradient-background {
    background-image: linear-gradient(269.59deg, #F2F2F2 1.25%, #F7F7F7 12.98%, #F8F8F8 29.33%, #FAFAFA 59.58%);
  }