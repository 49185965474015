.transaction-summary-card {
  width: 100%;
  flex: 1;
  padding: 20px 40px 20px 40px;
  border-radius: 9px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  flex-direction: column;
}

.pdfBody {
  position: absolute;
  left: -100%;
  top: -100%;
  z-index: -100;
}