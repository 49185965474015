.sm-main-card{
    background-color: #F2F2F2;
    padding: 16px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }
  
  .sm-sub-card {
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid #E4E4E4;
    border-radius: 9px;
  }

  .select-menu-option-box-img {
     height: 30px;
  }


  .summary-side-card-prefund {
    flex: 1;
    padding: 20px 40px 20px 40px;
    gap: 16px;
    border-radius: 9px 9px 9px 9px;
    background-color: #F2F2F2;
    border: 1px solid #B3B3B3;
    max-width: 410px;
    min-width: 200px;
  }