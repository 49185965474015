.sm-main-card{
  background-color: #F2F2F2;
  padding: 16px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.sm-sub-card {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 1px solid #E4E4E4;
  border-radius: 9px;
}

.stepper-button{
  padding: 0px !important;
  width: fit-content;
}

.sa-body-box{
  width: 386px;
  height: 60px;
  padding: 10px 16px 10px 16px;
  border-radius: 9px 9px 9px 9px;
  border: 1px solid #F2F2F2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-main-card{
  flex: 1;
  padding: 20px 40px 20px 40px;
  gap: 20px;
  border-radius: 9px 9px 9px 9px;
  background-color: #FFFFFF;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  gap:20px;
  max-width: 466px;
  min-width: 303px;
}

.summary-side-card {
  flex: 1;
  padding: 20px 40px 20px 40px;
  gap: 16px;
  border-radius: 9px 9px 9px 9px;
  /* opacity: 0px; */
  background-color: #F2F2F2;
  border: 1px solid #B3B3B3;
  max-width: 410px;
  min-width: 298px;
}

.summary-caution-box{
  width: 410px;
  top: 4910px;
  left: 1825px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 6px 6px 6px 6px;
  background-color: #FFFFFF;
  border: 1px solid #F04C4C;
}

.summary-caution-icon{
  width: 20px;
  height: 20px;
  color: #E90000;
}

.approval-pending-screen {
  max-width: 440px;
  flex: 1;
  padding: 20px 40px 40px 40px;
  gap: 44px;
  border-radius: 9px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ap-screen-body {
  border: 1px solid #E4E4E4;
  width: 265px;
  height: 179px;
  padding: 24px 16px 24px 16px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
  align-items: center;
}