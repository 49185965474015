.pa-login-container {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.pa-login-card {
    position: fixed;
    top: 0;
    left: 0;
    width: 650px;
    height: 100vh;
    padding: 40px, 60px, 40px, 60px;
    background-color: #ffffff;
}

.pa-login-sub-card{
    width: 100%;
    height: 100%;
    padding: 30px 60px 30px 60px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pa-login-sub-card::-webkit-scrollbar {
  display: none;
}

.pa-login-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 20px;
}

.pa-login-header span{
    color:#092C4C;
    width: 460px; 
    height: 94px;
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 34px;
    line-height: 46.87px;
    text-align: left;
}

.pa-login-header-text{
  color: #092C4C;
  width: 460px;
  height: 32px !important;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 34px;
  line-height: 46.87px;
  text-align: left;
}

.pa-header-nav{
    display: flex;
    flex-direction: row; 
    height: 30px;
    align-items: center; 
    justify-content: center;
    column-gap: 10px;
    cursor: pointer;
}

.otp-input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 25px;
    margin-top: 0;
}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
    .pa-login-card {
        width: 550px;
    }
    .pa-login-sub-card {
        padding: 40px 60px 40px 60px;
    }
    .otp-input-box{
        column-gap: 10px;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1250px) {
    .pa-login-card {
        width: 500px;
    }
    .pa-login-sub-card {
        padding: 30px 50px 30px 50px;
    }
    .otp-input-box {
        column-gap: 10px;
    }
}

@media screen and (min-width: 960px) and (max-width: 1050px) {
    .pa-login-card {
        width: 450px;
    }

    .pa-login-sub-card {
        padding: 30px 50px 30px 50px;
    }

    .otp-input-box {
        column-gap: 5px;
    }
}

@media screen and (max-width: 960px) {
    .pa-login-card {
        width: 400px;
    }

    .pa-login-sub-card {
        padding: 30px 50px 30px 50px;
    }

    .pa-login-header span {
        font-size: 30px;
    }

    .otp-input-box {
        column-gap: 5px;
        transform: scale(0.9);
        margin-left: -30px;
    }
}

.pa-login-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.pa-login-body p {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 46.87px;
    text-align: left;
}

.pa-login-logo-trouble {
    width: 150px;
    height: 104px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 135px;
}

.pa-login-heading {
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 24px;
    color: #202020;
    line-height: 41.66px;
    font-style: normal;
    margin-top: 16px;
}

.pa-login-sub-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #202020;
    margin-top: 8px;
}

.pa-login-password-strength {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4e4e4e;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.pa-login-password-rules {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4e4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

.pa-login-password-strength-hidden {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4e4e4e;
  margin-top: 2px;
  display: flex;
  visibility: hidden;
}

.pa-button {
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 45px;
    background: #1A4FD6;
    border-radius: 8px;
    color: white;
    margin-top: 32px;
    font-size: 20px;
}

.pa-input-box-div {
    display: inline-block;
    width: 100%;
    background-color: #FFFFFF;
    height: 45px;
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 0px 5px rgba(31, 41, 55, 0.12);
    gap: 8px;
    margin-bottom: 10px;
}

.pa-mfa-input-box-div {
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    height: 55px;
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 0px 5px rgba(31, 41, 55, 0.12);
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 30px;
}

.pa-input-email {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 20px;
    padding-left: 48px;
    border-radius: 5px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #202020;
}

.pa-input-email:focus {
    outline-color: #1A4FD6;
}

.pa-input-password {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 20px;
    padding-left: 48px;
    padding-right: 52px;
    border-radius: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #202020;
    border-bottom: 3px solid white;
}

.pa-input-password:focus {
    outline: none;
}

.pa-email-icon {
    width: 18px;
    height: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 19px;
}

.pa-key-icon {
    width: 16px;
    height: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 19px;
}

.pa-forget-password-enabled {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A4FD6;
    cursor: pointer;
    align-self: flex-end;
}

.pa-forget-password {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #202020;
    cursor: not-allowed;
    margin-top: 24px;
}

.pa-sign-in-with-google-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    padding: 14px 10px;
    gap: 20px;
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6.2069px rgba(31, 41, 55, 0.12);
    border-radius: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
}

.pa-card-heading {
    display: flex;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #202020;
    margin-top: 32px;
}

.pa-card-heading-icon {
    margin-top: 3px;
    margin-right: 25px;
    margin-left: 40px;
}

.pa-qr-image-login {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.pa-mfa-code {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #525C76;
}

.pa-copy-icon {
    width: 24px;
    height: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 33px;
    cursor: pointer;
}

.pa-otp-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 50px;
    height: 53px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(31, 41, 55, 0.12);
    border-radius: 12px;
    -moz-appearance: textfield;
    text-align: center;
}

.pa-otp-inputs:focus {
    outline-color: #1A4FD6;
}

.pa-otp-inputs::-webkit-inner-spin-button,
.pa-otp-inputs::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
